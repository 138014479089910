import { SPLASH_BG_URL } from "./settings";

// Display Strings (non-translatable)
export const SITE_TITLE = 'AccessiTech';
export const ACCESSITECH = 'AccessiTech';
export const COMPANY_TITLE = 'AccessiTech LLC'

// i18n stringKey keys
export const TAGLINE = 'TAGLINE';
export const FIVERR_LABEL = 'FIVERR_LABEL';
export const LINKEDIN_LABEL = 'LINKEDIN_LABEL';
export const TWITTER_LABEL = 'TWITTER_LABEL';
export const TWITCH_LABEL = 'TWITCH_LABEL';
export const YOUTUBE_LABEL = 'YOUTUBE_LABEL';
export const GMAIL_LABEL = 'GMAIL_LABEL';
export const ACCESSITECH_LOGO_LABEL = 'ACCESSITECH_LOGO_LABEL';
export const REDDIT_LABEL = 'REDDIT_LABEL';
export const GITHUB_LABEL = 'GITHUB_LABEL';
export const GITHUB_ISSUES = 'GITHUB_ISSUES';
export const VISION_P1 = 'VISION_P1';
export const VISION_P2 = 'VISION_P2';
export const VISION_P3 = 'VISION_P3';
export const PURPOSE_HEADER = 'PURPOSE_HEADER';
export const PURPOSE_P1 = 'PURPOSE_P1';
export const PURPOSE_P2 = 'PURPOSE_P2';
export const PURPOSE_P3 = 'PURPOSE_P3';
export const PURPOSE_P4 = 'PURPOSE_P4';
export const REACH_OUT_HEADER = 'REACH_OUT_HEADER';
export const ISSUES_P1 = 'ISSUES_P1';
export const COPYRIGHT_P1 = 'COPYRIGHT_P1';
export const SOCIAL_MEDIA_BUTTONS = 'SOCIAL_MEDIA_BUTTONS';
export const FOOTER = 'FOOTER';
export const CONTACT_LIST = "CONTACT_LIST";
export const REPORTING_AN_ISSUE = "REPORTING_AN_ISSUE";
export const COPYRIGHT = 'COPYRIGHT';
export const HEADER = 'HEADER';
export const APP_ROOT = 'APP_ROOT';
export const ABOUT_ACCESSITECH = 'ABOUT_ACCESSITECH';
export const PURPOSE_PIC_ALT = 'PURPOSE_PIC_ALT';
export const FOOTER_TWITTER = 'FOOTER_TWITTER';
export const FOOTER_TWITCH = 'FOOTER_TWITCH';
export const FOOTER_YOUTUBE = 'FOOTER_YOUTUBE';
export const FOOTER_LINKEDIN = 'FOOTER_LINKEDIN';
export const FOOTER_REDDIT = 'FOOTER_REDDIT';
export const FOOTER_FIVERR = 'FOOTER_FIVERR';
export const SERVICES_HEADER = 'SERVICES_HEADER';
export const SERVICES_P1 = "SERVICES_P1";
export const SERVICES_P2 = "SERVICES_P2";
export const SERVICES_P3 = "SERVICES_P3";
export const MENTORSHIP_HEADER = 'MENTORSHIP_HEADER';
export const MENTORSHIP_P1 = 'MENTORSHIP_P1';
export const MENTORSHIP_P2 = 'MENTORSHIP_P2';
export const MENTORSHIP_P3 = 'MENTORSHIP_P3';
export const MENTORSHIP_CTA = 'MENTORSHIP_CTA';
export const CONSULTATION_EMAIL_SUBJECT = "CONSULTATION_EMAIL_SUBJECT";
export const MENTORSHIP_EMAIL_SUBJECT = "MENTORSHIP_EMAIL_SUBJECT";
export const CONSULTATION_HEADER = 'CONSULTATION_HEADER';
export const CONSULTATION_P1 = 'CONSULTATION_P1';
export const CONSULTATION_P2 = 'CONSULTATION_P2';
export const CONSULTATION_P3 = 'CONSULTATION_P3';
export const CONSULTATION_CTA = 'CONSULTATION_CTA';
export const PRODUCTION_HEADER = 'PRODUCTION_HEADER';
export const PRODUCTION_P1 = 'PRODUCTION_P1';
export const PRODUCTION_P2 = 'PRODUCTION_P2';
export const PRODUCTION_P3 = 'PRODUCTION_P3';
export const PRODUCTION_P4 = 'PRODUCTION_P4';
export const PRODUCTION_CTA = 'PRODUCTION_CTA';
export const QA_HEADER = 'QA_HEADER';
export const QA_P1 = 'QA_P1';
export const QA_CTA = 'QA_CTA';
export const CLICK_TO_COPY = "CLICK_TO_COPY";
export const COPY_SUCCESS_MESSAGE = "COPY_SUCCESS_MESSAGE";
export const COPY_FAIL_MESSAGE = "COPY_FAIL_MESSAGE";
export const SKIP_TO_MAIN_CONTENT = "SKIP_TO_MAIN_CONTENT";

// Magical Strings
export const WHITE = 'white';
export const TRANSPARENT = 'transparent';
export const DARK_BLUE = "#061A32";
export const TARGET_BLANK = '_blank';
export const EN = 'en';
export const TWITTER_HANDLE = 'accessiT3ch';
export const REDDIT_HANDLE = 'u/accessiTech';
export const SPLASH_BG = `url(${SPLASH_BG_URL})`;
export const PRIMARY = "primary";
export const SECONDARY = "secondary";
export const TERTIARY = "tertiary";
export const BODY = 'body';
export const ESCAPE = 'Escape';

// URLs
export const ROOT = "/";
export const FIVERR_URL = "https://www.fiverr.com/accessitech";
export const FIVERR_AUDIT_URL = "https://www.fiverr.com/accessitech/audit-websites-for-wcag-accessibility-compliance";
export const FIVERR_MENTORSHIP_URL = "https://www.fiverr.com/accessitech/teach-you-web-development";
export const FIVERR_QA_URL = "https://www.fiverr.com/accessitech/review-your-frontend-code";
export const TWITTER_URL = "https://twitter.com/accessiT3ch";
export const LINKEDIN_URL = "https://www.linkedin.com/company/accessitech/";
export const YOUTUBE_URL = "https://www.youtube.com/channel/UCcke0DIj4p7QMFEKyFTU8RA";
export const GMAIL_URL = "mailto:accessit3ch@gmail.com";
export const REDDIT_URL = "https://www.reddit.com/user/accessitech";
export const GITHUB_ORG_URL = "https://github.com/AccessiTech";
export const GITHUB_ISSUES_URL = "https://github.com/AccessiTech/AccessiTech/issues";
export const GITHUB_DISCUSSIONS_URL = "https://github.com/AccessiTech/AccessiTech/discussions/33";
export const TWITCH_URL = "https://www.twitch.tv/accessitech";
